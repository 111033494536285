<template>
  <v-card>
    <v-card-title class="">
      <span class="me-3">Recent Contact Us</span>
      <v-spacer></v-spacer>
      <v-btn small class="text-xs" color="primary darken-2" :to="{ name: 'contact_us' }">View All</v-btn>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="usreList"
      item-key="full_name"
      class="table-rounded"
      :items-per-page="5"
      disable-sort
    >
      <!-- name -->
      <template #[`item.full_name`]="{item}">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.full_name }}</span>
          <small>Contact: {{ item.phone_number }}</small>
        </div>
      </template>
      <template #[`item.subject`]="{item}">
        {{ item.subject }}
      </template>
      <!-- status -->
      <template #[`item.status`]="{item}">
        <v-chip
          small
          :color="statusColor[status[item.status]]"
          class="font-weight-medium"
        >
          {{ status[item.status] }}
        </v-chip>
      </template>
      <!-- status -->
      <template #[`item.replied`]="{item}">
        <v-chip
          small
          :color="statusColor[status[item.replied]]"
          class="font-weight-medium"
        >
          {{ status[item.replied] }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import data from './datatable-data'

export default {
  setup() {
    const statusColor = {
      /* eslint-disable key-spacing */
      UnRead: 'error',
      Read: 'primary',
      Replied: 'success',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Name', value: 'full_name' },
        { text: 'Subject', value: 'subject' },
        { text: 'Category', value: 'category' },
        { text: 'Status', value: 'status' },
        { text: 'Replied', value: 'replied' },
      ],
      usreList: data,
      status: {
        1: 'UnRead',
        2: 'Read',
        3: 'Unreplied',
        4: 'Replied',
      },
      statusColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
}
</script>
