var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{},[_c('span',{staticClass:"me-3"},[_vm._v("Recent Contact Us")]),_c('v-spacer'),_c('v-btn',{staticClass:"text-xs",attrs:{"small":"","color":"primary darken-2","to":{ name: 'contact_us' }}},[_vm._v("View All")])],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.usreList,"item-key":"full_name","items-per-page":5,"disable-sort":""},scopedSlots:_vm._u([{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.full_name))]),_c('small',[_vm._v("Contact: "+_vm._s(item.phone_number))])])]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.subject)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor[_vm.status[item.status]]}},[_vm._v(" "+_vm._s(_vm.status[item.status])+" ")])]}},{key:"item.replied",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.statusColor[_vm.status[item.replied]]}},[_vm._v(" "+_vm._s(_vm.status[item.replied])+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }