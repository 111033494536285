<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
    >
      <dashboard-congratulation></dashboard-congratulation>
    </v-col>
    <v-col
      cols="12"
      md="8"
    >
      <dashboard-statistics-card></dashboard-statistics-card>
    </v-col>

    <v-col
      cols="12"
      sm="6"
      md="4"
    >
      <dashboard-weekly-overview></dashboard-weekly-overview>
    </v-col>

    <v-col
      cols="12"
      md="4"
      sm="6"
    >
      <dashboard-card-total-earning></dashboard-card-total-earning>
    </v-col>

    <v-col
      cols="12"
      md="4"
    >
      <v-row class="match-height">
        <v-col
          cols="12"
          sm="6"
        >
          <statistics-card-vertical
            :change="totalDeliveries.change"
            :color="totalDeliveries.color"
            :icon="totalDeliveries.icon"
            :statistics="totalDeliveries.statistics"
            :stat-title="totalDeliveries.statTitle"
            :subtitle="totalDeliveries.subtitle"
          ></statistics-card-vertical>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <statistics-card-vertical
            :change="totalPending.change"
            :color="totalPending.color"
            :icon="totalPending.icon"
            :statistics="totalPending.statistics"
            :stat-title="totalPending.statTitle"
            :subtitle="totalPending.subtitle"
          ></statistics-card-vertical>
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <statistics-card-vertical
            :change="totalCancelled.change"
            :color="totalCancelled.color"
            :icon="totalCancelled.icon"
            :statistics="totalCancelled.statistics"
            :stat-title="totalCancelled.statTitle"
            :subtitle="totalCancelled.subtitle"
          ></statistics-card-vertical>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <statistics-card-vertical
            :change="totalProfits.change"
            :color="totalProfits.color"
            :icon="totalProfits.icon"
            :statistics="totalProfits.statistics"
            :stat-title="totalProfits.statTitle"
            :subtitle="totalProfits.subtitle"
          ></statistics-card-vertical>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      cols="12"
    >
      <dashboard-card-deposit-withdraw-interrupt></dashboard-card-deposit-withdraw-interrupt>
    </v-col>
    <v-col cols="12">
      <dashboard-datatable></dashboard-datatable>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'

// demos
import DashboardStatisticsCard from './DashboardStatisticsCard.vue'
import DashboardCardTotalEarning from './DashboardCardTotalEarning.vue'
import DashboardCardDepositWithdrawInterrupt from './DashboardCardDepositWithdrawInterrupt.vue'
import DashboardWeeklyOverview from './DashboardWeeklyOverview.vue'
import DashboardDatatable from './DashboardDatatable.vue'
import DashboardCongratulation from './DashboardCongratulation.vue'

export default {
  components: {
    StatisticsCardVertical,
    DashboardCongratulation,
    DashboardStatisticsCard,
    DashboardCardTotalEarning,
    DashboardCardDepositWithdrawInterrupt,
    DashboardWeeklyOverview,
    DashboardDatatable,
  },
  setup() {
    const totalDeliveries = {
      statTitle: 'Total Deliveries',
      icon: mdiPoll,
      color: 'success',
      subtitle: 'Monthly Target (XAF)',
      statistics: '256.6k',
      change: '+42%',
    }

    const totalPending = {
      statTitle: 'Total Pending',
      icon: mdiCurrencyUsd,
      color: 'secondary',
      subtitle: 'Monthly Target (XAF)',
      statistics: '17,800',
      change: '-15%',
    }

    // vertical card options
    const totalCancelled = {
      statTitle: 'Total Faults',
      icon: mdiLabelVariantOutline,
      color: 'error',
      subtitle: 'Monthly Target (XAF)',
      statistics: '86,000',
      change: '-18%',
    }

    const totalProfits = {
      statTitle: 'Total Profits',
      icon: mdiHelpCircleOutline,
      color: 'warning',
      subtitle: 'Monthly Target (XAF)',
      statistics: '15,000',
      change: '+18%',
    }

    return {
      totalDeliveries,
      totalPending,
      totalCancelled,
      totalProfits,
    }
  },
}
</script>
